<template>

  <div>
    <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>

    <!-- Table Container Card -->
    

    
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mobile_tab_max_width_flex mobile_margin_bottom_1rem"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="filterTable"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="9"
            class="mobile_tab_max_width_flex all_btn_tab"
          >
            <div class="d-flex align-items-center justify-content-end mobile-view mobile_tab_display_block">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1 mobile-margin-bottom mobile-margin-input mobile_margin_bottom_1rem w-50"
                placeholder="Search..."
                @input="filterTable"
                autocomplete="off"
              />

              <b-button
                  v-if="checkPermission($route.name, 'Add')"
                  variant="warning"
                  class="mobile-margin-bottom mr-1"
                  :to="{ name: 'add-inventory-area'}"
                >
                  <span class="text-nowrap">Add</span>
                </b-button>

              <b-button
                  v-if="checkPermission($route.name, 'Export PDF')"
                  variant="danger"
                  class="mobile-margin-bottom mr-1"
                  @click="generatePDF()"
                  :disabled="items.length == 0"
                >
                  <span class="text-nowrap">Export PDF</span>
                </b-button>

                <b-button
                  v-if="checkPermission($route.name, 'View All Area Users')"
                  variant="info"
                  class="mobile-margin-bottom"
                  :to="{ name: 'inventory-area-user' }"
                >
                  View All Area Users
                </b-button>
              
              
            </div>
          </b-col>
        </b-row>

      </div>
      <b-table sticky-header
        ref="refUserListTable"
        class="position-relative sTable"
        :items="dataList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :class="items.length <= 10 ? 'remove_overflow' : 'add_overflow'"
      >

        <template #cell(info)="items">             
            <feather-icon
              :icon="items.detailsShowing ? 'EyeIcon' : 'EyeOffIcon'"
              @click="items.toggleDetails"
            />
        </template>

        <!-- Column: Role -->
        <template #cell(name)="items">
           
            <span class="wordBreak" @click="items.toggleDetails">{{ items.item.name | capitalize}}</span>
         
        </template>

        

        
        
        <template #cell(status)="items">
           
            <b-badge
              pill
              :variant="variantColor(items.item.status)"
              class="text-capitalize"
              @click="items.toggleDetails"
            >
              {{ items.item.status}}
            </b-badge>
         
        </template>

        <template #cell(created_at)="items">
           
            <span @click="items.toggleDetails">{{sitedateTime(items.item.created_at, 'DD MMM YYYY', 'HH:mm')}}</span>
         
        </template>

        <template #cell(actions)="items">

          <b-button
            size="sm"
            variant="warning"
            class="mr-1"
            :to="{ name: 'inventory-area-user', params: { area_id:items.item._id } }"
            v-if="checkPermission($route.name, 'Area')"
          >
            <!-- View Area Users -->
            Area
          </b-button>
          <b-button
            size="sm"
            variant="warning"
            class="mr-1"
            :to="{ name: 'area-cluster', params: { area_id:items.item._id } }"
            v-if="checkPermission($route.name, 'Cluster')"
          >
            Cluster
          </b-button>

          <!-- <b-link v-b-tooltip.hover.v-warning title="PDF" variant="outline-warning" @click="generatePDF(items.item)">
              <feather-icon icon="FileIcon" class="mediumSize ml-1" />	
          </b-link> -->

          <b-link v-b-tooltip.hover.v-warning title="Edit" variant="outline-warning" :to="{ name: 'edit-inventory-area', params: { id: items.item._id } }" v-if="checkPermission($route.name, 'Edit')">
              <feather-icon icon="EditIcon" class="mediumSize ml-1" />	
          </b-link>

          <b-link v-b-tooltip.hover.v-warning :title= "items.item.status == 'active' ? 'Mark Inactive' : 'Mark Active'" variant="outline-warning" @click="statusChange(items.item)" v-if="checkPermission($route.name, 'Change Status')">
                <feather-icon :icon="items.item.status == 'active' ? 'CheckCircleIcon' : 'CircleIcon'" class="mediumSize ml-1" />	
            </b-link>
            

          <b-link v-b-tooltip.hover.v-warning title="Delete" variant="outline-warning" @click="deleteItem(items.item)" v-if="checkPermission($route.name, 'Delete')">
              <feather-icon icon="Trash2Icon" class="mediumSize ml-1" />	
          </b-link>

          




           
        </template>

        <template #row-details="items">
            <div class="p-1 px-2 fbDetail mb-1">
                <b-row>

                  <b-col cols="12" xl="12" >
                      <table class="mt-2 mt-xl-0 w-100">
                          <tr class="mb-1">
                              <th class="pb-50" style="width: 16%">
                                <div class="d-flex align-items-center">
                          
                                  <div class="ml-1">
                                    <h6 class="mb-0 mediumFonts">
                                      Area Manager 
                                    </h6>
                                  <!--  <small>Monthly Sales</small> -->
                                  </div>
                                </div>
                              </th>
                              <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                <span v-if="items.item.user != null">{{ items.item.user.user.full_name | capitalize }} <br>( {{ items.item.user.user.role | removeUnderCapitalize }} )</span>
                                <span v-else>{{ 'N/A' }}</span>
                              </td>

                            

                              <th class="pb-50" style="width: 16%">
                                  <div class="d-flex align-items-center">
                              
                                    <div class="ml-1">
                                      <h6 class="mb-0 mediumFonts">
                                        Contact Number 
                                      </h6>
                                    <!--  <small>Monthly Sales</small> -->
                                    </div>
                                  </div>
                              </th>
                              <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                <span v-if="items.item.user != null">{{ items.item.user.user.phone_number  }}</span>
                                <span v-else> {{ 'N/A' }}</span>
                              </td>

                              <th class="pb-50" style="width: 16%">
                                    <div class="d-flex align-items-center">
                          
                                      <div class="ml-1">
                                        <h6 class="mb-0 mediumFonts">
                                          Project Sites 
                                        </h6>
                                      <!--  <small>Monthly Sales</small> -->
                                      </div>
                                    </div>
                              </th>
                              <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">

                                  <span v-b-tooltip.hover.v-primary.top :title="getFromAsgSite(items.item.sites)" v-if="items.item.sites.length > 0 && getFromAsgSite(items.item.sites).split(',').length > 3">
                                    {{ getFromAsgSite2(items.item.sites)[0] }}
                                    <b-badge v-if="getFromAsgSite2(items.item.sites)[1] != ''" pill variant="primary"> +{{ getFromAsgSite2(items.item.sites)[1] }} </b-badge>
                                  </span>
                                  <span v-if="items.item.sites.length > 0 && getFromAsgSite(items.item.sites).split(',').length <= 3">
                                    {{ getFromAsgSite2(items.item.sites)[0] }}
                                  </span>
                                  <span v-if="items.item.sites.length == 0">{{ 'N/A' }}</span>
                              </td>

                              
                            </tr>
                        <tr class="mb-1">
                                <th class="pb-50" style="width: 16%">
                                  <b-button
                                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                      variant="outline-secondary"
                                      @click="items.toggleDetails"
                                      size="sm"
                                      class="ml-1"
                                    >
                                      Hide Detail
                                    </b-button>
                                </th>
                                <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                    
                                </td>
                                <th class="pb-50" style="width: 16%">
                                </th>
                                <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                    
                                </td>
                                <th class="pb-50" style="width: 16%">
                                </th>
                                <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                    
                                </td>
                          </tr>
                      </table>
                  </b-col>

                </b-row>
            </div>

        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRecords"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @input="filterTable"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BBreadcrumb,BCardBody, VBTooltip, BFormCheckbox
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { POST_API } from "../../../store/actions.type"
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardBody,
    vSelect,
    BBreadcrumb
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip
  },
  data() {
    return {

        tableColumns: [
          
          { key: 'info', label: 'INFO', sortable: false, thStyle: { width: '5%' } },
          { key: 'name', label: 'Area Name', sortable: true , thStyle:  {width: '30%'}},
          { key: 'status', label: 'Status', sortable: true , thStyle:  {width: '15%'}},
          { key: 'created_at', label: 'Added On', sortable: true , thStyle:  {width: '20%'}},
          { key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '30%'}},
        ],
        
        items:[],
        isSortDirDesc: false,
        perPageOptions: [10, 20, 50, 100],
        perPage : 10,
        sortBy : 'id',
       
        totalRecords : 0,
        currentPage : 1,
        searchQuery:'',
        from:null,
        to:null,
    }
  },
  methods : {
    
    breadCrumb(){
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to:null,
        text: 'Inventory'
      },{
        to:null,
        text: 'Settings'
      },{
        to:null,
        text: 'Area Management',
        active:true
      }];
      return item;
    },
    
    dataList(){
      return this.$store.dispatch(POST_API, {
          data:{
            page: this.currentPage,
            keyword: this.searchQuery,
            rowsPerPage:this.perPage,
            sortBy:this.sortBy,
            sortDirection:this.isSortDirDesc,
            
          },
          api: '/api/inventory-area-list'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
          } else {
              this.items = this.$store.getters.getResults.data.docs;
              this.totalRecords = this.$store.getters.getResults.data.total;
              this.perPage = this.$store.getters.getResults.data.limit;
              this.from = this.$store.getters.getResults.data.from
              this.to = this.$store.getters.getResults.data.to
              return this.items;
          }
      });
    },
    filterTable(){
      this.$refs.refUserListTable.refresh();
    },
    variantColor(status){
      if (status == 'active') {
        return 'success';
      }else{
        return 'primary';
      }
    },
    
    statusChange(item){

      if (item.status == 'active' && item.subuser == true ) {
              
          var msg     = 'Some User linked with this area. Are you sure want to inactivate this record?';
          var message = 'Area Inactivated Successfully.';

      } else if (item.status == 'active' && item.subuser == false) {
        var msg = 'Are you sure want to inactivate this record?';
        var message = 'Area Inactivated Successfully.';
      }
      else {
        var msg = 'Are you sure want to activate this record?';
        var message = 'Area Activated Successfully.';
      }
      //console.log('aaabbbbbbb');

      Swal.fire({
                title: 'Please Confirm',
                text: msg,
                icon: 'warning',
                position: 'top-center',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
              })
            .then(result => {
              if (result.value) {
                  
                  return this.$store.dispatch(POST_API, {
                    data:{
                          id: item._id,
                          status:item.status
                        },
                    api:"/api/change-inventory-area-status",
                      })
                      .then(() => {
                        console.log(this.$store.getters.containsErrors,'aaaaaa');  
                        if (this.$store.getters.containsErrors) {

                              //this.error_message = this.$store.getters.getErrors;
                              this.errorAlert();
                          } else {
                            
                            Swal.fire({
                              position: 'center',
                              icon: 'success',
                              title: message,
                              showConfirmButton: false,
                              timer: 1500
                            })
                            
                            this.$refs.refUserListTable.refresh();
                          }
                      });
              }
          })
          .catch(err => {
             
          })
    },
    deleteItem(item){
      var msg = 'Are you sure want to delete this record?';
      var message = 'Area Deleted Successfully.';


      Swal.fire({
        title: 'Please Confirm',
        text: msg,
        icon: 'warning',
        position: 'top-center',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })
        .then(result => {
          if (result.value) {
            return this.$store.dispatch(POST_API, {
              data: {
                id: item._id,
                status: 'deleted'
              },
              api: "/api/change-inventory-area-status",
            })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                } else {

                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: message,
                    showConfirmButton: false,
                    timer: 1500
                  })

                  this.$refs.refUserListTable.refresh();
                }
              });
          }
        })
        .catch(err => {

        })
      
    },
    getFromAsgSite(site) {
      if (site.length == 0) {
        return 'N/A';
      } else {
        var arr = [];
        for (var i = 0; i < site.length; i++) {
          if (this.$store.getters.currentUser.role == 'administrator') {
            arr.push(site[i].site_name);
          } else if (this.$store.getters.currentUser.om_sites.indexOf(site[i]._id) >= 0) {
            arr.push(site[i].site_name);
          }
        }

        return arr.join(', ');

      }
    },
    getFromAsgSite2(site) {
      if (site.length == 0) {
        return 'N/A';
      } else {
        var arr = [];
        for (var i = 0; i < site.length; i++) {
          if (this.$store.getters.currentUser.role == 'administrator') {
            arr.push(site[i].site_name);
          } else if (this.$store.getters.currentUser.om_sites.indexOf(site[i]._id) >= 0) {
            arr.push(site[i].site_name);
          }
        }

        if (arr.length > 3) {
          return [arr[0] + ', ' + arr[1] + ', ' + arr[2], (arr.length - 3)]
        } else {
          return [arr.join(', '), ''];
        }

      }
    },
    generatePDF(){
      var baseurl = null;
      baseurl = process.env.VUE_APP_SERVER_URL + "/api/inventory-area-users-pdf?organization=" + this.$store.getters.currentUser.organization+"&keyword="+ (this.searchQuery == null ? '' : this.searchQuery);
      window.open(baseurl, '_blank');
    }
  },
  mounted(){
    
    
  }
  
}
</script>


<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
